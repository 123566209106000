<template lang="pug">
div
  app-dialog( v-model="$_show" :header="title"
             :width="noDialog ? 400 : 312"
             @close="handleClose" :closeButtonHidden="noDialog ? true : false"
             :hideOverlay="noDialog ? true : false"
             :confirmText="$t('button.save')" @confirm="handleClick"
             :confirmDisabled="!valid")
    app-section
      v-form( v-model="valid" ref="formProfile" :disabled="disabled")
        .d-flex.justify-center.my-2(v-if="!isRegistration")
        app-file-input(v-model="avatar" ref="fileInput" :max-size="10000000" :formats="[]" style="display: none" )
        app-avatar.avatarEdit.mb-6(
          :url="fileSrc || getFileSrc" :text="user.name"
          :colorObj="getBgColor(user.uuid)" :size=64
          @click="openFileInput"
        )        
              
        app-text-field(v-model="user.name" :label="$t('profile.form.name')" :rules="[rules.required]" focus autocomplete="new-password" )
        app-text-field(v-model="user.email" :label="$t('profile.form.login')" :rules="[rules.required, rules.email]" autocomplete="new-password" )
        app-text-field(v-model="user.phone" :label="$t('profile.form.phone')" autocomplete="new-password" )
        div(v-if="isRegistration")
          app-text-field.mb-7(
            v-model="user.password" 
            :rules="[rules.required]"
            :append-icon="showPSWD ? 'visibility' : 'visibility_off'"
            :type="showPSWD ? 'text' : 'password'"
            :label="$t('profile.form.password')"
            @click:append="showPSWD = !showPSWD"
            autocomplete="new-password"
          )
        app-text-field(v-model="user.groupMarketing" :label="$t('profile.form.groupMarketing')" )
        v-menu(ref='trialDeadLine' :close-on-content-click="false" :return-value.sync='user.trialDeadLine' transition='scale-transition' min-width='auto' offset-y )
          template( v-slot:activator='{ on }' )
            v-text-field.mr-2( v-model='user.trialDeadLine' v-on='on' :label="$t('profile.form.trialAccess')" prepend-icon='mdi-calendar' readonly light )
          v-date-picker(v-model="user.trialDeadLine" :min="minDate" first-day-of-week=1 no-title scrollable @input="$refs.trialDeadLine.save(user.trialDeadLine)" )  
        //- div(v-if="grantTypes")
          AppSelect.mb-7(v-model="user.grantType" :label="$t('profile.form.role')" :items="grantTypes" item-text="title" return-object)
    //- .text-right.mt-4( v-if="!disabled" slot="footer")
      v-btn.text-none.dialog-confirm-action( color="accent" height="32" small depressed light @click="handleClick" ) {{ $t('button.save') }}
</template>

<script>
import DialogToggable from '@/components/app/DialogToggable'
import uniqolor from 'uniqolor';
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { SourcePath } from '@app/SourcePath'

export default {
  name: 'ProfileForm',
  mixins: [DialogToggable],

  props: {
    user: Object,
    listRoles: Array,
    tariffList: Array,
    grantTypes: Array,
    isRegistration: Boolean,
    isAdminPanel: Boolean,
    disabled: Boolean,
    title: {
      type: String,
      default: function () {
        return this.$t('profile.form.title')
      }
    },
    noDialog: {
      default: false,
      type: Boolean
    },
  },

  watch: {
    avatar(newAvatar) {
      if (newAvatar) {
        const newFileSrc = URL.createObjectURL(newAvatar)
        this.fileSrc = newFileSrc
        this.$emit('selectedFile', newAvatar)
      }
    },

  },

  methods: {
    ...mapActions("users", ["setAvatar"]),
    ...mapMutations("authUser", ["setUser"]),

    openFileInput () {
      this.$refs.fileInput.openFileInput();
    },

    handleClick (event) { 
      if (this.$refs.formProfile.validate()) {
        this.$emit('click', event)
      }
    },

    getBgColor(uuid) {
      return uniqolor(uuid, { format: 'rgb' })
    },

    handleBlur(event) {
      if (!this.isRegistration)
        this.loading = true
        this.handleClick(event)
    },

    handleClose() {
      this.$emit('close')
    }
  },

  computed: {
    ...mapGetters("authUser", ["myUUID"]),

    getFileSrc () {
      return this.user.avatar ? SourcePath.avatar(this.user.avatar) : null
    },
  },
  
  data () {
    return {
      fileSrc: null, 
      rules: {
        required: value => !!value || this.$t('error.require'),
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"+]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return value ==='admin' ? true : pattern.test(value) || this.$t('error.email')
        },
      },
      showPSWD: false,
      loading: false,
      dialog: {
        avatar: false
      },
      avatar: null,
      valid: false,
      minDate: new Date(new Date().setDate(new Date().getDate() + 1)).toISOString().substr(0, 10),
    }
  },
}
</script>

<style scoped>
.avatarEdit {
  cursor: pointer;
  margin: 0 auto; 
  display :block;
}
</style>